import React from 'react';
import { I18nWrapper } from '../../I18nWrapper';

type TwoFaEnabledProps = {
  handleDisabling: (event: React.FormEvent<HTMLFormElement>) => Promise<void>
}

export const TwoFaEnabled = ({ handleDisabling }: TwoFaEnabledProps) => {
  return (
    <div id="twofa">
      <h4 className="widgettitle ctitle"><I18nWrapper id="twofa.totp.title" /></h4>
      <div id="disable2fa">
        <div className="twofatitle "><I18nWrapper id="twofa.status" /><span id="twoFaStatus" className="enabled">&nbsp;<I18nWrapper id="twofa.status.enabled" /></span></div>
        <div className="twofadesc "><I18nWrapper id="twofa.enabled.description" /></div>
        <form name="frmdisable2fa" onSubmit={handleDisabling}>
          <button type="submit" className="btn btn-default btn-block btnDisabled"><I18nWrapper id="twofa.enabled.button.disable" /></button>
        </form>
      </div>
    </div>
  )
}
