import React, { useEffect, useState, useContext } from 'react';

import { TwoFaDisabled } from "./components/TwoFaDisabled";
import { TwoFaEnabled } from "./components/TwoFaEnabled";
import { TwoFaConfiguring } from "./components/TwoFaConfiguring";
import { TwoFaDisabling } from "./components/TwoFaDisabling";
import { UserContext } from '../Context';
import { enableTwoFa, getTwoFaConfiguration, disableTwoFa } from '../../api';

import { useSnackbar } from 'notistack';
import { useIntl } from 'react-intl';

enum TwoFaState {
  DISABLED = 'disabled',
  ENABLED = 'enabled',
  CONFIGURING = 'configuring',
  DISABLING = 'disabling' // State in which the 2FA is enabled but the user wants to disable it (he needs to enter a code + the account password)
};

export const TwoFa = () => {
  const [currentTwoFaState, setCurrentTwoFaState] = useState(TwoFaState.CONFIGURING);
  const [urlQrCode, setUrlQrCode] = useState('https://tenor.com/view/load-loading-gif-5662595.gif');
  const [backupCode, setBackupCode] = useState('');
  const [code, setCode] = useState('');
  const [password, setPassword] = useState('');
  const [readyForDisplay, setReadyForDisplay] = useState(false);

  const userContext = useContext(UserContext);

  const { enqueueSnackbar } = useSnackbar();
  const intl = useIntl();

  useEffect(() => {
    if (!userContext.isLoggedIn || !userContext.user || !userContext.user.twoFaSettings) {
      return;
    }

    // Set current state from context
    const currentState = (userContext.user.twoFaSettings.enabled === true) ? TwoFaState.ENABLED : TwoFaState.DISABLED;
    setCurrentTwoFaState(currentState);

    // If user has been redirected to this page because TwoFa is enforced and it's not enabled, display an alert
    try {
      const url = new URL(window.location.href);
      const forceConfigure = url.searchParams.get('forceConfigure');
      if (forceConfigure) {
        enqueueSnackbar(intl.formatMessage({ id: "twofa.notification.two_fa_enforced" }), { variant: 'info' });
        
      }
    } catch (e) {
      console.error('error while parsing url', e);
    }

    setReadyForDisplay(true);
  }, [userContext, intl, enqueueSnackbar]);

  if (!userContext.isLoggedIn || !userContext.user || !userContext.user.twoFaSettings || !readyForDisplay) {
    return null;
  }

  async function handleConfigure(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    // Display configuring interface (could also be called enabling)
    setCurrentTwoFaState(TwoFaState.CONFIGURING);

    // Load data (secret code will be generated automatically)
    try {
      const json = await getTwoFaConfiguration();

      if (json.success) {
        setUrlQrCode(json.data.urlQrCode);
        setBackupCode(json.data.secretCode);
        return;
      }

      let errorMsg = null;

      switch (json.data.code) {
        case 'twoFaAlreadyEnabled':
          setCurrentTwoFaState(TwoFaState.ENABLED);
          return;

        default:
          errorMsg = intl.formatMessage({ id: "common.alert.general_error" }, { support_email: (window as any).SUPPORT_EMAIL })?.toString();
          break;
      }

      enqueueSnackbar(errorMsg, { variant: 'error' });
    } catch (e: any) {
      console.error('An issue happened while configuring')
    }
  }

  async function handleEnable(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    try {
      const json = await enableTwoFa(backupCode, code);

      setCode('');

      if (json.success) {
        setCurrentTwoFaState(TwoFaState.ENABLED);
        enqueueSnackbar(intl.formatMessage({ id: "twofa.notification.twofa_enabled" }), { variant: 'success' });

        // Reset vars and fields
        setBackupCode('');
        setUrlQrCode('https://tenor.com/view/load-loading-gif-5662595.gif');
        return;
      }

      let errorMsg = null;

      switch (json.data.code) {
        case 'twoFaAlreadyEnabled':
          setCurrentTwoFaState(TwoFaState.ENABLED);
          return;

        case 'wrongTwoFaCode':
          errorMsg = intl.formatMessage({ id: "common.alert.wrong_otp_code" })?.toString();
          break;

        // Move the 2 cases below in a general handler?
        case 'missingParameter':
          errorMsg = intl.formatMessage({ id: "common.alert.empty_field" })?.toString();
          break;

        default:
          errorMsg = intl.formatMessage({ id: "common.alert.general_error" }, { support_email: (window as any).SUPPORT_EMAIL })?.toString();
          break;
      }

      enqueueSnackbar(errorMsg, { variant: 'error' });
    } catch (e: any) {
      console.error('An issue happened while configuring')
    }
  }

  async function handleDisabling(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    // Display disabling interface
    setCurrentTwoFaState(TwoFaState.DISABLING);
  };

  async function handleDisable(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    try {
      const json = await disableTwoFa(password, code);

      if (json.success) {
        setCurrentTwoFaState(TwoFaState.DISABLED);
        enqueueSnackbar(intl.formatMessage({ id: "twofa.notification.twofa_disabled" }), { variant: 'success' });

        // Reset vars and fields
        setCode('');
        setPassword('');
        return;
      }

      let errorMsg = null;

      switch (json.data.code) {
        case 'twoFaNotEnabled':
          errorMsg = intl.formatMessage({ id: "twofa.alert.twofa_not_enabled" })?.toString();
          break;

        case 'wrongTwoFaCode':
          errorMsg = intl.formatMessage({ id: "common.alert.wrong_otp_code" })?.toString();
          break;

        case 'wrongPassword':
          errorMsg = intl.formatMessage({ id: "common.alert.wrong_password" })?.toString();
          break;

        case 'missingParameter':
          errorMsg = intl.formatMessage({ id: "common.alert.empty_field" })?.toString();
          break;

        default:
          errorMsg = intl.formatMessage({ id: "common.alert.general_error" }, { support_email: (window as any).SUPPORT_EMAIL })?.toString();
          break;
      }

      enqueueSnackbar(errorMsg, { variant: 'error' });
    } catch (e: any) {
      console.error('An issue happened while configuring')
    }
  }

  function handleCode(event: React.ChangeEvent<HTMLInputElement>) {
    setCode(event.target.value);
  }

  function handlePassword(event: React.ChangeEvent<HTMLInputElement>) {
    setPassword(event.target.value);
  }

  // Pass values as functions to avoid unncessary computation (if they were passed "normally", code would be executed)

  const mapping: { [x in TwoFaState]: () => JSX.Element } = {
    [TwoFaState.CONFIGURING]: () => <TwoFaConfiguring handleEnable={handleEnable} urlQrCode={urlQrCode} secretCode={backupCode} backupCode={backupCode} code={code} handleCode={handleCode} />,
    [TwoFaState.DISABLED]: () => <TwoFaDisabled handleConfigure={handleConfigure} />,
    [TwoFaState.DISABLING]: () => <TwoFaDisabling handleDisable={handleDisable} code={code} handleCode={handleCode} password={password} handlePassword={handlePassword} />,
    [TwoFaState.ENABLED]: () => <TwoFaEnabled handleDisabling={handleDisabling} />
  }

  return mapping[currentTwoFaState]();
};