import React from 'react';

import { FormattedMessage } from 'react-intl';

export const I18nWrapper = ({ id, description, defaultMessage, values }: { id: string, description?: string, defaultMessage?: string, values?: Record<string, React.ReactNode> | undefined }) => {
  if (!description) {
    description = id;
  }

  if (!defaultMessage) {
    defaultMessage = id;
  }

  return (
    <FormattedMessage id={id} description={description} defaultMessage={defaultMessage} values={{ ...values, i: (...chunks: any) => <i>{chunks}</i>, b: (...chunks: any) => <b>{chunks}</b>, u: (...chunks: any) => <u>{chunks}</u> }} />
  );
};
