import React, { SyntheticEvent } from 'react';
import { I18nWrapper } from '../../I18nWrapper';
// import { useIntl } from 'react-intl';
import Switch from "react-switch";

type OrganizationGeneralSettingsProps = {
  handleSwitchTwoFaEnforcement: (checked: boolean, event: MouseEvent | SyntheticEvent<MouseEvent | KeyboardEvent, Event>) => void,
  currentTwoFaEnforcementState: boolean,
  // handleEnablePasswordReset: (event: React.ChangeEvent<HTMLInputElement>) => void,
}

export const OrganizationGeneralSettings = ({ handleSwitchTwoFaEnforcement, currentTwoFaEnforcementState }: OrganizationGeneralSettingsProps) => {
  // const intl = useIntl();

  // Create a SwitchSetting component? With a title, a desc, and a switch?

  return (
    <div id="securitysettings">
      <h4 className="widgettitle ctitle"><I18nWrapper id="organization.general_settings.title" /></h4>
      <div id="2fa">
        <div className="optionTitle"><I18nWrapper id="organization.general_settings.twofa_enforcement.title" /></div>
        <div className="optionContent">
          <p className="optionDesc"><I18nWrapper id="organization.general_settings.twofa_enforcement.description" /></p>
      
          <Switch onChange={handleSwitchTwoFaEnforcement} checked={currentTwoFaEnforcementState} />
        </div>
      </div>
    </div>
  )
}
