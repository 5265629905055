import React from 'react';
import { I18nWrapper } from '../../I18nWrapper';
import { useIntl } from 'react-intl';

type TwoFaDisablingProps = {
  handleDisable: (event: React.FormEvent<HTMLFormElement>) => Promise<void>
  code: string,
  handleCode: (event: React.ChangeEvent<HTMLInputElement>) => void,
  password: string,
  handlePassword: (event: React.ChangeEvent<HTMLInputElement>) => void,
}

export const TwoFaDisabling = ({ handleDisable, code, handleCode, password, handlePassword }: TwoFaDisablingProps) => {
  const intl = useIntl();

  // Form disable with password and secret code
  return (
    <div id="disable2fa" >
      <form id="frmdisable" onSubmit={handleDisable}>
        <h4 className="widgettitle ctitle"><I18nWrapper id="twofa.disabling.title" /></h4>
        <p><I18nWrapper id="twofa.disabling.description" /></p>
        <h3><I18nWrapper id="common.current_password" /></h3>
        <input
          type="password"
          name="password"
          className="form-control password"
          id="password"
          value={password}
          onChange={handlePassword}
          required
          placeholder={intl.formatMessage({ id: "common.account_password" })}
        />
        <h3><I18nWrapper id="common.otp_code" /></h3>
        <input
          type="number"
          name="code"
          className="form-control code"
          placeholder={intl.formatMessage({ id: "common.otp_code" })}
          id="code"
          value={code}
          required
          onChange={handleCode}
        />
        <p><I18nWrapper id="twofa.disabling.otp_code_indication" /></p>
        <button type="submit" className="btn btn-danger btn-block btnDisable"><I18nWrapper id="twofa.disabling.button.disable" /></button>
      </form>
    </div>
  )
}
