import React from 'react';
import { Nullable } from '../types';
import { getBaseName } from '../utils';
import { I18nWrapper } from './I18nWrapper';

export const IphonePreview = ({ sender, message, children }: { sender?: Nullable<string>, message: string, children?: Nullable<React.ReactNode> }) => {
  const date = new Date();
  const dateStr = date.getHours() + ':' + ('0' + date.getMinutes()).slice(-2);

  if (sender?.trim() === '') {
    sender = '36180';
  }

  return (
    <div id="preview" className={'previewIphone'} style={{ background: 'url(' + getBaseName() + '/img/iphoneX2-compressor.png) center top no-repeat', backgroundSize: 'cover' }}>
      <div style={{ width: '86%', margin: '15% 0 0 7%' }}>
        <header>
          <span className="left leftmessages" style={{ fontSize: '12px' }}><I18nWrapper id="common.messages" /></span>
          <h2 style={{ paddingTop: '12%', fontSize: '12px' }}>{sender}</h2>
        </header>

        <div className="messages-wrapper" style={{ background: 'white', overflow: 'hidden', paddingTop: '2px', overflowY: 'auto' }}>
          <div className="" style={{ textAlign: 'center', fontSize: '11px', color: '#8e8e93', width: '100%', margin: 0, fontWeight: 300 }}>
            <p><b><I18nWrapper id="common.today" /></b> {dateStr}</p>
          </div>
          <div className="message to" style={{ backgroundColor: '#2095FE', padding: '0px 0px 7px 0px', color: '#fff', marginLeft: '10px', fontSize: '14px', lineHeight: '18px', wordWrap: 'break-word' }}>
            <div id="message">{message}</div>
          </div>
        </div>
        {children}
      </div>
    </div>
  );
};