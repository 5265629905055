import React from 'react';
import { Nullable } from './types';

export enum AlertType {
  INFO = "info",
  SUCCESS = "success",
  ERROR = "error",
  WARNING = "warning",
}

export const Alert = ({ text, type, children }: { text?: Nullable<string>, type: AlertType, children?: Nullable<React.ReactNode> }) => {
  return (
    <div className={"alert alert-" + type } style={{ marginTop: '10px', whiteSpace: 'pre-line' }}>
      <button data-dismiss="alert" className="close" type="button">x</button>
      {text}
      {children}
    </div>
  );
};
