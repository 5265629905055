import React, { useState } from 'react';
import jwt_decode from 'jwt-decode';
import { useParams } from 'react-router-dom';
import { getBaseName } from '../../../utils';
import { updatePassword } from '../../../api';
import { I18nWrapper } from '../../I18nWrapper';

import PasswordStrengthBar from 'react-password-strength-bar';
import { useSnackbar } from 'notistack';
import { useIntl } from 'react-intl';

function getHeaderFromToken(token: string) {
  try {
    let decoded: any = jwt_decode(token);
    if (Date.now() >= decoded.exp * 1000) {
      return false;
    }
    return decoded;
  } catch (e) {
    return false;
  }
}

interface IInput {
  password: string;
  confirmPassword: string;
  alert: string;
}

type PossibleInputKeys = keyof IInput;

export const ResetPass = () => {
  const { token } = useParams<{ token: string }>();
  const { enqueueSnackbar } = useSnackbar();
  const intl = useIntl();

  const initialValue: IInput = {
    password: '',
    confirmPassword: '',
    alert: ''
  };
  const [input, setInput] = useState(initialValue);

  // Check token validity
  let who = getHeaderFromToken(token);
  if (who === false) {
    return (
      <div><I18nWrapper id="resetpass.alert.link_expired" />&nbsp;<a href={getBaseName() + '/auth/forgottenpass'}><I18nWrapper id="common.button.forgot_password" /></a></div>
    )
  }

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const newInput = { ...input };
    newInput[event.target.name as PossibleInputKeys] = event.target.value;
    setInput(newInput);
  }

  function validate() {
    if (!input.password) {
      enqueueSnackbar(intl.formatMessage({ id: "common.alert.empty_field" })?.toString(), { variant: 'error' });
      return false;
    }

    if (!input.confirmPassword) {
      enqueueSnackbar(intl.formatMessage({ id: "common.alert.empty_field" })?.toString(), { variant: 'error' });
      return false;
    }

    //laregexp de qualite
    const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*-/])(?=.{8,})"); // n'accepte pas les / et les -
    if (!strongRegex.test(input['password'])) {
      enqueueSnackbar(intl.formatMessage({ id: "resetpass.alert.weak_password" })?.toString(), { variant: 'error' });
      return false;
    }

    if (input.password && input.confirmPassword && input.password !== input.confirmPassword) {
      enqueueSnackbar(intl.formatMessage({ id: "resetpass.alert.passwords_dont_match" })?.toString(), { variant: 'error' });
      return false;
    }

    return true;
  }

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    if (validate()) {
      const newInput = { ...input };

      const json = await updatePassword(newInput.password, token);
      if (json.success) {
        setInput(initialValue);

        window.location.href = getBaseName() + "/auth/login?passupdated=1";
        return;
      }

      enqueueSnackbar(intl.formatMessage({ id: "common.alert.general_error" }, { support_email: (window as any).SUPPORT_EMAIL })?.toString(), { variant: 'error' });
    }
  }

  return (
    <div className="loginwrapper">
      <div className="loginwrap zindex100 animate2 bounceInDown">
        <div><img src={getBaseName() + '/' + (window as any).DOMAIN_NAME + '/img/logo.png'} className="logo" alt="Logo allmysms" /></div>
        <h1 className="logintitle"><span className="iconfa-lock"></span> <I18nWrapper id="resetpass.form.title" />
          <span className="subtitle"><I18nWrapper id="resetpass.form.subtitle" /></span>
        </h1>
        <div className="loginwrapperinner">
          <div id="myresetpassdiv">
            <form name="resetpasswdfrm" onSubmit={handleSubmit}>
              <div className="form-group">
                <input
                  type="password"
                  name="password"
                  value={input.password}
                  onChange={handleChange}
                  className="form-control"
                  placeholder={intl.formatMessage({ id: "resetpass.form.input.new_password" })}
                  id="password"
                  required
                />
                <PasswordStrengthBar
                  password={input.password}
                  scoreWords={[intl.formatMessage({ id: "resetpass.passwordstrengthbar.weak" }), intl.formatMessage({ id: "resetpass.passwordstrengthbar.weak" }), intl.formatMessage({ id: "resetpass.passwordstrengthbar.okay" }), intl.formatMessage({ id: "resetpass.passwordstrengthbar.good" }), intl.formatMessage({ id: "resetpass.passwordstrengthbar.strong" })]}
                  shortScoreWord={intl.formatMessage({ id: "resetpass.passwordstrengthbar.too_short" })}
                />
              </div>

              <div className="form-group" style={{ marginTop: '10px' }}>
                <input
                  type="password"
                  name="confirmPassword"
                  value={input.confirmPassword}
                  onChange={handleChange}
                  className="form-control"
                  placeholder={intl.formatMessage({ id: "resetpass.form.input.confirm_new_password" })}
                  id="confirmPassword"
                  required
                />
              </div>

              <div className="" style={{ marginTop: '10px' }}>
                <button type="submit" className="btn btn-default btn-block"><I18nWrapper id="resetpass.form.button.save_new_password" /></button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="loginshadow animate3 fadeInUp"></div>
    </div>
  );
};
