
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import 'promise-polyfill/src/polyfill';
import './objectAssignPolyfill';
import 'url-polyfill';


window.__webpack_public_path__ = "/build";

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
