import { Typography } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { useReducer, useState } from 'react';
import { useIntl } from 'react-intl';
import { getStatisticsByDateAndPrefix, getPrefixesDetails } from '../../../api';
import { IPrefixDetails, IStatisticsByDay, IStatisticsByPrefix } from '../../../types';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TablePagination from '@material-ui/core/TablePagination';
import { withStyles, Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { format } from 'date-fns';
import { map, groupBy, orderBy, sumBy, flatMap} from 'lodash';
import { I18nWrapper } from '../../I18nWrapper';
import { getBaseName, getVariableFromWindow } from '../../../utils';

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: '#858585',
      color: theme.palette.common.white,
    },
  })
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

enum StatisticsByDayState {
  UNLOADED = 'unloaded',
  LOADING = 'settings',
  LOADED = 'loaded',
}

export const StatisticsByDay = () => {
  const [currentStatisticsByDayState, setCurrentStatisticsByDayState] = useState(StatisticsByDayState.UNLOADED);
  const [statsByDay, setStatsByDay] = useState<Array<IStatisticsByDay>>([]);
  const [statsByPrefix, setStatsByPrefix] = useState<Array<IStatisticsByPrefix>>([]);
  const [prefixesDetails, setPrefixesDetails] = useState<Array<IPrefixDetails>>([]);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const { enqueueSnackbar } = useSnackbar();
  const intl = useIntl();

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  async function handleLoadData(event: React.MouseEvent<HTMLButtonElement> | null) {
    setCurrentStatisticsByDayState(StatisticsByDayState.LOADING);

    const startDate = getVariableFromWindow<string>('startDate');
    const endDate = getVariableFromWindow<string>('endDate');

    if (!startDate || !endDate) {
      enqueueSnackbar(
        intl.formatMessage({ id: 'common.alert.general_error' }, { support_email: getVariableFromWindow<string>('SUPPORT_EMAIL') }),
        { variant: 'error' }
      );

      return;
    }

    // Retrieve statistics and prefix details
    const [jsonStatisticsByDateAndPrefix, jsonPrefixesDetails] = await Promise.all([getStatisticsByDateAndPrefix(startDate, endDate), getPrefixesDetails()]);

    setPrefixesDetails(jsonPrefixesDetails);

    if (!jsonStatisticsByDateAndPrefix || !jsonStatisticsByDateAndPrefix.length) {
      enqueueSnackbar(intl.formatMessage({ id: 'common.alert.no_data_found' }), { variant: 'error' });

      return;
    }

    setStatsByDay(jsonStatisticsByDateAndPrefix);

    // Find stats by prefix
    const allStats = flatMap(jsonStatisticsByDateAndPrefix, x => x.stats);
    const groups = groupBy(allStats, x => x.prefix);

    let statsByPrefixTmp = map(groups, (group, key) => ({prefix: key, nbSms: sumBy(group, x => x.nbSms)}));
    statsByPrefixTmp = orderBy(statsByPrefixTmp, x => x.nbSms, 'desc')

    setStatsByPrefix(statsByPrefixTmp);

    setCurrentStatisticsByDayState(StatisticsByDayState.LOADED);

    // Depending on the amount of prefix, use either date or destination as columns?
  }

  function getPrefixDetails(prefix: string) {
    return (
      prefixesDetails.find((elt) => elt.prefix == prefix) || {
        prefix: prefix,
        country: 'Unknown',
        code: '',
      }
    );
  }

  function getNbSmsByDateAndPrefix(date: string, prefix: string) {
    let statsByDate = statsByDay.find((elt) => elt.date === date);

    if (!statsByDate) {
      return 0;
    }

    let statsByPrefix = statsByDate.stats.find((elt) => elt.prefix === prefix);

    if (!statsByPrefix) {
      return 0;
    }

    return statsByPrefix.nbSms;
  }

  const classes = useStyles();

  return (
    <>
      {(currentStatisticsByDayState === StatisticsByDayState.UNLOADED ||
        currentStatisticsByDayState === StatisticsByDayState.LOADING) && (
        // <Button type="submit" variant="contained" color="primary" fullWidth>
        <button className="btn btn-default btn-block" style={{ display: 'inline-flex' }} onClick={handleLoadData}>
          <span style={{ margin: 'auto' }}>
            <img
              src={getBaseName() + '/img/spinner.gif'}
              style={{
                width: '15px',
                float: 'left',
                marginRight: '10px',
                display: currentStatisticsByDayState === StatisticsByDayState.LOADING ? 'block' : 'none',
              }}
              alt="loading"
            />
            <I18nWrapper id="campaign.statistics.button.load_statistics_by_destination" />
          </span>
        </button>
      )}
      {currentStatisticsByDayState === StatisticsByDayState.LOADED && (
        <>
          <Typography variant="h6" id="tableTitle" component="div">
            <I18nWrapper id="campaign.statistics.title.statistics_by_destination" />
          </Typography>
          <TableContainer component={Paper}>
            <Table className={classes.table} size="small" aria-label="Statistics by prefix">
              <TableHead>
                <TableRow>
                  <StyledTableCell style={{ whiteSpace: 'nowrap' }}>
                    <I18nWrapper id="campaign.statistics.title.destination" />
                  </StyledTableCell>

                  {statsByDay.map((col) => (
                    <StyledTableCell component="th" scope="row" key={col.date}>
                      {format(new Date(col.date), 'dd/MM')}
                    </StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {statsByPrefix.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((prefixStats) => (
                  <StyledTableRow key={prefixStats.prefix}>
                    <StyledTableCell style={{ whiteSpace: 'nowrap' }}>
                      {getPrefixDetails(prefixStats.prefix).country + ' (' + prefixStats.nbSms + ' SMS)'}
                    </StyledTableCell>

                    {statsByDay.map((col) => (
                      <StyledTableCell key={col.date + '_' + prefixStats.prefix}>
                        {getNbSmsByDateAndPrefix(col.date, prefixStats.prefix)}
                      </StyledTableCell>
                    ))}
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={statsByPrefix.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      )}
    </>
  );
};
