import React, { useReducer, useEffect } from "react";
import { getCurrentUser } from "../api";
import { UserType } from "../User";

type SetUserActionType = {
  type: "SET_USER_ACTION_TYPE";
  user: UserType;
};

export const setUser = (user: UserType): SetUserActionType => {
  return {
    type: "SET_USER_ACTION_TYPE",
    user
  };
};

type SetNoUserActionType = {
  type: "SET_NO_USER_ACTION_TYPE";
};

export const setNoUser = (): SetNoUserActionType => {
  return {
    type: "SET_NO_USER_ACTION_TYPE"
  };
};

type UnsetUserActionType = {
  type: "UNSET_USER_ACTION_TYPE";
};

export const unsetUser = (): UnsetUserActionType => {
  return {
    type: "UNSET_USER_ACTION_TYPE"
  };
};

type ActionType = SetUserActionType | SetNoUserActionType | UnsetUserActionType;
interface StateType {
  loading: boolean;
  isLoggedIn: boolean;
  user: UserType | null;
};

const initialState: StateType = {
  // we will attempt to fetch the data first thing
  // this prevents unnecessary re-renders
  loading: true,
  isLoggedIn: false,
  user: null
};

function UserReducer(state: StateType, action: ActionType): StateType {
  switch (action.type) {
    case "SET_USER_ACTION_TYPE":
      return {
        ...state, // Creates a shallow copy of the state because we never want to edit the state passed in parameter
        isLoggedIn: true,
        loading: false,
        user: { ...action.user }
      };

    case "SET_NO_USER_ACTION_TYPE":
      return {
        ...state,
        isLoggedIn: false,
        loading: false
      };

    case "UNSET_USER_ACTION_TYPE":
      return {
        ...state,
        isLoggedIn: false,
        user: null
      };
    default:
      throw new Error(
        `User Reducer recieved an action it couldn't resolve, please check your dispatch.`
      );
  }
}

interface UserContextPropsType extends StateType {
  setUser: (user: UserType) => void;
  unsetUser: () => void;
};

export const UserContext = React.createContext<UserContextPropsType>(
  {} as UserContextPropsType
);

export const UserProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(UserReducer, initialState);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const user = await getCurrentUser();

        if (!user) {
          return;
        };

        dispatch(setUser(user));
      } catch(e: any) {
        console.error(e);
      }
    }

    fetchUser();
  }, []);

  return (
    <UserContext.Provider
      value={{
        ...state,
        setUser: user => dispatch(setUser(user)),
        unsetUser: () => dispatch(unsetUser())
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
