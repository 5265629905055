import makeStyles from "@material-ui/core/styles/makeStyles";

// To move on root to share styles?

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 220,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 220,
  },
}));