import axios from 'axios';

import { SenderType } from './Sender';
import {
  IPrefixDetails,
  ISmsInfo,
  IStatisticsByDay,
  ITemplate,
} from './types';
import { UserType } from './User';
import { getApiPrefix } from './utils';
import { SendExpressConfig } from './SendExpressConfig';

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

const client = axios.create({
  baseURL: getApiPrefix(),
  withCredentials: true,
});


interface GetCurrentUserData {
  success: boolean,
  message: string,
  data?: UserType
}

export const getCurrentUser = async () => {
  try {
    const result = await client.get<GetCurrentUserData>('/auth/me');
    const json = result.data;

    if (json.success) {
      return json.data;
    }

    return null; // why not returning json and let the script decide what to do? will it not make json.success buggy?
  } catch (e: any) {
    if (e.response && e.response.data) {
      return null;
    }
    console.log("Unknown error while fetching /me", e);
    return null;
  }
};

interface TwoFaConfigurationData {
  success: boolean,
  message: string,
  data: {
    code: string;
    urlQrCode: string;
    secretCode: string;
  }
}

export const getTwoFaConfiguration = async () => {
  const res = await client.get<TwoFaConfigurationData>('/twofa/configure');
  return res.data;
};

interface TwoFaEnableData {
  success: boolean,
  message: string,
  data: {
    code: string;
  }
}

export const enableTwoFa = async (secretCode: string, twoFaCode: string) => {
  const res = await client.post<TwoFaEnableData>('/twofa/enable', {
    secretCode,
    twoFaCode,
  });
  return res.data;
};

interface TwoFaDisableData {
  success: boolean,
  message: string,
  data: {
    code: string;
  }
}

export const disableTwoFa = async (password: string, twoFaCode: string) => {
  const res = await client.post<TwoFaDisableData>('/twofa/disable', {
    password: password,
    twoFaCode: twoFaCode,
  });
  return res.data;
};

interface SignInData {
  success: boolean,
  message: string,
  data: {
    code: string;
    action?: string;
    redirectUrl?: string;
    parameterName?: string;
  }
}

export const signIn = async (
  username: string,
  password: string,
  twoFaCode?: string,
  recaptcha?: string,
) => {
  let params: any = {
    username: username,
    password: password,
  };

  if (twoFaCode) {
    params.twoFaCode = twoFaCode;
  }

  if (recaptcha) {
    params.recaptchaResponse = recaptcha;
  }

  const res = await client.post<SignInData>('/auth/signin', params);
  return res.data;
};

interface UpdatePasswordData {
  success: boolean,
  message: string,
  data: {
    code: string;
    parameterName?: string;
  }
}

export const updatePassword = async (password: string, token: string) => {
  let params: any = {
    password: password,
    token: token,
  };

  const res = await client.post<UpdatePasswordData>('/auth/updatepassword', params);
  return res.data;
};

interface EnableOrganizationTwoFaEnforcementData {
  success: boolean,
  message: string,
  data: {
    code: string;
  }
}

export const enableOrganizationTwoFaEnforcement = async (organizationId: number) => {
  const res = await client.post<EnableOrganizationTwoFaEnforcementData>('/organization/enableTwoFaEnforcement', {
    organizationId,
  });
  return res.data;
};

interface DisableOrganizationTwoFaEnforcementData {
  success: boolean,
  message: string,
  data: {
    code: string;
  }
}

export const disableOrganizationTwoFaEnforcement = async (organizationId: number) => {
  const res = await client.post<DisableOrganizationTwoFaEnforcementData>('/organization/disableTwoFaEnforcement', {
    organizationId,
  });
  return res.data;
};

interface SendSmsExpressData {
  success: boolean,
  message: string,
  data: {
    code: string;
    action?: string;
    redirectUrl?: string;
    nbSms?: number;
    nbContacts?: number;
    sendingType?: string;
  }
}

export const sendSmsExpress = async (sender: string, message: string, phoneNumbers: string[], sendingDate: string, unicode: boolean) => {
  const res = await client.post<SendSmsExpressData>('/send/ajaxexpress', {
    sender,
    message,
    phoneNumbers,
    sendingDate,
    unicode,
  });
  return res.data;
};

interface GetSendersData {
  success: boolean,
  message: string,
  data?: SenderType[]
}

export const getSendersSms = async () => {
  try {
    const result = await client.get<GetSendersData>('/api/sender?type=sms');
    const json = result.data;

    if (json.success) {
      return json.data || [];
    }

    return [];
  } catch (e: any) {
    if (e.response && e.response.data) {
      return [];
    }
    console.log("Unknown error while fetching data", e);
    return [];
  }
};

interface GetSmsExpressTemplatesData {
  success: boolean,
  message: string,
  data?: ITemplate[]
}

export const getSmsExpressTemplates = async () => {
  try {
    const result = await client.get<GetSmsExpressTemplatesData>('/api/template');
    const json = result.data;

    if (json.success) {
      return json.data || [];
    }

    return [];
  } catch (e: any) {
    if (e.response && e.response.data) {
      return [];
    }
    console.log("Unknown error while fetching data", e);
    return [];
  }
};

interface GetSmsExpressConfigData {
  success: boolean,
  message: string,
  data?: SendExpressConfig
}

export const getSmsExpressConfig = async () => {
  try {
    const result = await client.get<GetSmsExpressConfigData>('/api/configexpress/');
    const json = result.data;

    if (json.success) {
      return json.data || {
        allowCustomMessages: false
      };
    }

    return {
      allowCustomMessages: false
    };
  } catch (e: any) {
    if (e.response && e.response.data) {
      return {
        allowCustomMessages: false
      };
    }
    console.log("Unknown error while fetching data", e);
    return {
      allowCustomMessages: false
    };
  }
};

// Load templates from file directly
// export const getSmsExpressTemplates = async (): Promise<ITemplate[]> => {
//   return templates as ITemplate[];
// }

interface GetStatisticsByDateAndPrefixData {
  success: boolean,
  message: string,
  data?: Array<IStatisticsByDay>
}

export const getStatisticsByDateAndPrefix = async (startDate: string, endDate: string) => {
  try {
    const result = await client.get<GetStatisticsByDateAndPrefixData>('/campaign/ajaxstatisticsbydateandprefix?startDate=' + startDate + '&endDate=' + endDate);
    const json = result.data;

    if (json.success) {
      return json.data || [];
    }

    return [];
  } catch (e: any) {
    if (e.response && e.response.data) {
      return [];
    }
    console.log("Unknown error while fetching data", e);
    return [];
  }
};
interface GetPrefixesDetailsData {
  success: boolean,
  message: string,
  data?: Array<IPrefixDetails>
}

export const getPrefixesDetails = async () => {
  try {
    const result = await client.get<GetPrefixesDetailsData>('/campaign/ajaxgetprefixesdetails');
    const json = result.data;

    if (json.success) {
      return json.data || [];
    }

    return [];
  } catch (e: any) {
    if (e.response && e.response.data) {
      return [];
    }
    console.log("Unknown error while fetching data", e);
    return [];
  }
};

export const switchOrganizationReportPeriodicity = async (organizationId: number, period:string, checked:boolean) => {

  const res = await client.post<EnableOrganizationTwoFaEnforcementData>('/organization/switchreportingperiodicity', {
    organizationId,
    period,
    checked,
  });
  return res.data;
};

interface GetSmsInfoByMobileNumberData {
  success: boolean,
  message: string,
  data?: Array<ISmsInfo>
}

export const getSmsInfoByMobileNumber = async (mobileNumber: string, startDate: string, endDate: string) => {
  try {
    const result = await client.get<GetSmsInfoByMobileNumberData>('/campaign/ajaxsearchbynumber?mobileNumber= ' + mobileNumber + '&startDate=' + startDate + '&endDate=' + endDate);
    const json = result.data;

    if (json.success) {
      return json.data || [];
    }

    return [];
  } catch (e: any) {
    if (e.response && e.response.data) {
      return [];
    }
    console.log("Unknown error while fetching data", e);
    return [];
  }
};