import { Fragment, useEffect } from 'react';

import {
  Alert,
  AlertType,
} from '../../../Alert';
import { Nullable } from '../../../types';
import { I18nWrapper } from '../../I18nWrapper';

declare global {
  interface Window {
    APPLICATION_ENV: string;
    gtag: (...args: any[]) => void;
  }
}

export interface LoginSubscribeAlertInfo {
  alreadyActive: number;
  autoActivated: number;
}

// Déclaration de la fonction gtag_report_conversion
function gtag_report_conversion(url?: string) {
  if(window.APPLICATION_ENV === 'development') {
    return;
  }
  
  const callback = function () {
    if (typeof(url) != 'undefined') {
      window.location.href = url;
    }
  };
  window.gtag('event', 'conversion', {
      'send_to': 'AW-1072293607/60jUCMr47usYEOfNp_8D',
      'event_callback': callback
  });
  return false;
}

// export const LoginSubscribeAlert: React.FC<LoginSubscribeAlertInfo> = (props) => { // Other possible declaration
// export const LoginSubscribeAlert = (props: any) => { // Other possible declaration
// Note: props is always a single object param, this is why we need to declare it like { xxx } if we want to describe sub parts
export const LoginSubscribeAlert = ({ subscribeInfo }: { subscribeInfo: Nullable<LoginSubscribeAlertInfo> }) => {
  useEffect(() => {
    if(subscribeInfo?.alreadyActive === 1) {
      gtag_report_conversion();
    }
  }, [subscribeInfo]);

  if (!subscribeInfo || (subscribeInfo.autoActivated === undefined && subscribeInfo.alreadyActive === undefined)) {
    return null;
  }

  return (
    <Fragment>
      {
        subscribeInfo.alreadyActive === 1 && (
          <Alert type={AlertType.WARNING}>
            <I18nWrapper id="login.notification.registration_already_finalized_1" />
            <br /><br />
            <I18nWrapper id="login.notification.registration_already_finalized_2" />
            <br /><br />
            <I18nWrapper id="login.notification.registration_accelerate_procedure" values={{ support_email: (window as any).SUPPORT_EMAIL }} />
          </Alert>
        )
      }

      {
        subscribeInfo.autoActivated === 1 &&
        <Alert type={AlertType.WARNING}>
          <I18nWrapper id="login.notification.account_activated_1" />
          <br /><br />
          <I18nWrapper id="login.notification.account_activated_2" />
        </Alert>
      }

      {
        subscribeInfo.autoActivated === 0 &&
        <Alert type={AlertType.WARNING}>
          <I18nWrapper id="login.notification.registration_completed" />
          <br /><br />
          <I18nWrapper id="login.notification.registration_accelerate_procedure" values={{ support_email: (window as any).SUPPORT_EMAIL }} />
        </Alert>
      }
    </Fragment>
  )
};
