import React, { Fragment, useEffect, useRef, useState } from 'react';
import { getBaseName } from '../../../utils';
import { Alert, AlertType } from '../../../Alert';
import { LoginSubscribeAlert, LoginSubscribeAlertInfo } from './LoginSubscribeAlert';
import { signIn } from '../../../api';
import { Nullable } from '../../../types';
import { I18nWrapper } from '../../I18nWrapper';

import { useSnackbar } from 'notistack';
import { useIntl } from 'react-intl';
import ReCAPTCHA from "react-google-recaptcha";

interface IInput {
  loginOrEmail: string;
  password: string;
  twoFaCode: string;
}

type PossibleInputKeys = keyof IInput;

enum LoginState {
  LOGGED_OUT = 'logged_out',
  TWOFA = 'twofa',
  LOGGED_IN = 'logged_in',
};

export const Login = () => {
  const [currentLoginState, setCurrentLoginState] = useState(LoginState.LOGGED_OUT); // LOGGED_OUT
  const [redirectUrlPostLogin, setRedirectUrlPostLogin] = useState(''); // Url to redirect after login
  const [subscribeInfo, setSubscribeInfo] = useState<LoginSubscribeAlertInfo | null>(null);
  const [errorMessage, setErrorMessage] = useState<Nullable<string>>(''); // DO we really need this? or will we use snackbar?
  const [infoMessage, setInfoMessage] = useState('');
  const [submitButtonLoading, setSubmitButtonLoading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();
  const intl = useIntl();
  const recaptchaRef = useRef<any>();

  useEffect(() => {
    try {
      const url = new URL(window.location.href);
      const redirectPathEncoded = url.searchParams.get('r');
      if (redirectPathEncoded) {
        let redirectPath = atob(redirectPathEncoded);
        setRedirectUrlPostLogin(redirectPath);
      }

      const subscribeInfoEncoded = url.searchParams.get('g');
      if (subscribeInfoEncoded) {
        const objSubscribeInfo: LoginSubscribeAlertInfo = JSON.parse(atob(subscribeInfoEncoded as any));
        setSubscribeInfo(objSubscribeInfo);
      }

      const passUpdated = url.searchParams.get('passupdated');
      if (passUpdated) {
        enqueueSnackbar(intl.formatMessage({ id: "common.notification.password_updated" })?.toString(), { variant: 'success' });
      }

      const logged_out = url.searchParams.get('logged_out');
      if (logged_out && logged_out === 'timeout') {
        const inactivity = url.searchParams.get('inactivity');
        if (inactivity) {
          let info = intl.formatMessage({ id: "login.notification.logged_out_inactivity" })?.toString();

          if ((window as any).SEND_MESSAGE_BACKUPED) {
            info += "\n\n";
            info += intl.formatMessage({ id: "login.notification.logged_out_backup_message" })?.toString();
          }

          setInfoMessage(info);
        }
      }
    } catch (e) {
      console.error('error while parsing url', e);
    }
  }, [enqueueSnackbar, intl]);

  const initialValue: IInput = {
    loginOrEmail: '',
    password: '',
    twoFaCode: ''
  };

  const [input, setInput] = useState(initialValue);

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const newInput = { ...input };
    newInput[event.target.name as PossibleInputKeys] = event.target.value;
    setInput(newInput);
  }

  async function handleSubmit(event: React.FormEvent<HTMLFormElement> | null) {
    if (submitButtonLoading) {
      return;
    }

    event?.preventDefault();
    setSubmitButtonLoading(true);

    let recaptchaToken = undefined;
    if (recaptchaRef.current) {
      recaptchaToken = await recaptchaRef.current.getValue();

      // If token already exists, we need to refresh it
      if (recaptchaToken) {
        recaptchaToken = await recaptchaRef.current.reset();
      }

      recaptchaToken = await recaptchaRef.current.executeAsync();
    }

    setErrorMessage('');
    setInfoMessage('');

    try {
      let twoFaCode = undefined;
      if (currentLoginState === LoginState.TWOFA) {
        twoFaCode = input.twoFaCode;
      }

      const json = await signIn(input.loginOrEmail, input.password, twoFaCode, recaptchaToken);
      setSubmitButtonLoading(false);

      if (json.success === true) {
        // Handle 2FA if enabled on the account
        if (json.data.action === 'displayTwoFaForm') {
          setCurrentLoginState(LoginState.TWOFA);

          // Do not reset pass and login because they will serve for 2FA

          return;
        }

        setSubmitButtonLoading(true);
        // setCurrentLoginState(LoginState.LOGGED_IN);
        // enqueueSnackbar(intl.formatMessage({ id: "login.notification.successful_connection" })?.toString(), { variant: 'success' });

        // Redirect after login
        let redirectUrl = getBaseName() + '/index/connected';

        // If redirect param specified in response, use it
        if (json.data.action === 'redirect' && json.data.redirectUrl) {
          redirectUrl = json.data.redirectUrl;
        }
        // If a redirect was specified in the query string, use it
        else if (redirectUrlPostLogin !== '') {
          redirectUrl = redirectUrlPostLogin;
        }

        window.location.href = redirectUrl;
        return;
      }

      let errorMsg = null;

      if (json.data.code === 'limitOfTwoFaAttemptsReached') {
        errorMsg = intl.formatMessage({ id: "alert.alert.limit_twofa_attempts_reached" })?.toString();
        enqueueSnackbar(errorMsg, { variant: 'error' });
        setInput(initialValue);
        setCurrentLoginState(LoginState.LOGGED_OUT);
        return;
      }

      switch (json.data.code) {
        case 'missingParameter':
          errorMsg = intl.formatMessage({ id: "common.alert.empty_field" })?.toString();
          break;

        case 'accountDeactivated':
          errorMsg = intl.formatMessage({ id: "login.alert.account_not_activated" }, { support_email: (window as any).SUPPORT_EMAIL })?.toString();
          break;

        case 'passwordResetRequiredDueToInactivity':
          errorMsg = intl.formatMessage({ id: "login.alert.password_reset_required_due_to_inactivity" }, { email: json.data.parameterName})?.toString();
          break;

        case 'useLoginToSignIn':
          errorMsg = intl.formatMessage({ id: "login.alert.use_login" })?.toString();
          break;

        case 'wrongCredentials':
          errorMsg = intl.formatMessage({ id: "login.alert.wrong_login_or_password" })?.toString();
          break;

        case 'badCredentials':
          // IP banned
          errorMsg = intl.formatMessage({ id: "login.alert.wrong_login_or_password" })?.toString();
          break;

        case 'wrongCaptcha':
          errorMsg = intl.formatMessage({ id: "common.alert.wrong_captcha" })?.toString();
          break;

        case 'wrongTwoFaCode':
          errorMsg = intl.formatMessage({ id: "common.alert.wrong_otp_code" })?.toString();
          break;

        case 'wrongTwoFaCodeFormat':
          errorMsg = intl.formatMessage({ id: "common.alert.wrong_otp_code_format" })?.toString();
          break;

        case 'riskyIp':
          errorMsg = intl.formatMessage({ id: "login.alert.risky_ip_1" })?.toString();
          errorMsg += "\n";
          errorMsg += intl.formatMessage({ id: "login.alert.risky_ip_2" })?.toString();
          errorMsg += "\n";
          errorMsg += intl.formatMessage({ id: "login.alert.risky_ip_3" }, { support_email: (window as any).SUPPORT_EMAIL })?.toString();
          break;

        case 'passwordToUpdate':
          // No error message, let the redirect happen
          break;

        default:
          errorMsg = intl.formatMessage({ id: "common.alert.general_error" }, { support_email: (window as any).SUPPORT_EMAIL })?.toString();
          break;
      }

      setErrorMessage(errorMsg);

      // If redirect param specified in response, use it
      if (json.data.action === 'redirect' && json.data.redirectUrl) {
        window.location.href = json.data.redirectUrl;
      }
    } catch (e: any) {
      console.error('An error occured: ', e);
    }
  }

  // const mapping: { [x in LoginState]: () => JSX.Element } = {
  //   [LoginState.LOGGED_OUT]: () => <TwoFaConfiguring handleEnable={handleEnable} urlQrCode={urlQrCode} secretCode={backupCode} backupCode={backupCode} code={code} handleCode={handleCode} />,
  //   [LoginState.TWOFA]: () => <TwoFaDisabled handleConfigure={handleConfigure} />,
  //   [LoginState.LOGGED_IN]: () => <TwoFaDisabling handleDisable={handleDisable} code={code} handleCode={handleCode} password={password} handlePassword={handlePassword} />,
  // }

  return (
    <div className="loginwrapper">
      <div className="loginwrap zindex100 animate2 bounceInDown">
        <img src={(window as any).URL_LOGO} className="logo" alt="Logo allmysms" />

        {
          currentLoginState === LoginState.LOGGED_OUT &&
          <LoginSubscribeAlert subscribeInfo={subscribeInfo} />
        }

        <h1 className="logintitle" style={{ width: 'auto' }}><span className="iconfa-lock"></span> <I18nWrapper id={currentLoginState === LoginState.LOGGED_OUT ? "login.form.title.sign_in" : "common.title.enter_your_otp"} />
          <span className="subtitle"><I18nWrapper id={currentLoginState === LoginState.LOGGED_OUT ? "login.form.subtitle.welcome" : "common.subtitle.refer_to_authenticator_app"} /></span>
          {
            window.location.search.indexOf('ip') !== -1 &&
            <span className="subtitle">{(window as any).USER_IP}</span>
          }
        </h1>

        <div className="loginwrapperinner">
          <form id="loginform" method="post" onSubmit={handleSubmit}>

            {
              (window as any).RECAPTCHA_ENABLED &&
              <>
                <ReCAPTCHA
                  ref={recaptchaRef}
                  size="invisible"
                  sitekey={((window as any).RECAPTCHA_PUBLIC_KEY)}
                />
              </>
            }

            {
              currentLoginState === LoginState.LOGGED_OUT &&
              <Fragment>
                <p className="animate4 bounceIn">
                  <input
                    type="text"
                    id="loginOrEmail"
                    name="loginOrEmail"
                    placeholder={intl.formatMessage({ id: "common.login_or_email" })}
                    value={input.loginOrEmail}
                    onChange={handleChange}
                    required
                    autoFocus
                  />
                </p>
                <p className="animate5 bounceIn">
                  <input
                    type="password"
                    id="password"
                    name="password"
                    placeholder={intl.formatMessage({ id: "common.password" })}
                    autoComplete="current-password"
                    value={input.password}
                    onChange={handleChange}
                    required
                  />
                </p>
              </Fragment>
            }

            {
              currentLoginState === LoginState.TWOFA &&
              <Fragment>
                <p className="animate0 bounceIn">
                  <input
                    type="number"
                    id="twoFaCode"
                    name="twoFaCode"
                    placeholder={intl.formatMessage({ id: "common.otp_code" })}
                    value={input.twoFaCode}
                    onChange={handleChange}
                    required
                    autoFocus
                  />
                </p>
              </Fragment>
            }

            <p className="animate6 bounceIn">
              <button type="submit" className="btn btn-default btn-block" style={{ height: '28px', opacity: submitButtonLoading ? '0.65' : '1', cursor: submitButtonLoading ? 'auto' : 'hand' }}>
                <div style={{ marginTop: '-9px', position: 'absolute', left: '50%', transform: 'translateX(-50%' }} >
                  <img src={getBaseName() + "/img/spinner.gif"} style={{ width: '15px', float: 'left', marginRight: '10px', display: submitButtonLoading ? 'block' : 'none' }} alt="loading" />
                  <I18nWrapper id="common.button.sign_in" />
                </div>
              </button>
            </p>

            {
              currentLoginState === LoginState.LOGGED_OUT &&
              <p className="animate7 fadeIn"><a href={getBaseName() + '/auth/forgottenpass'}><span className="icon-question-sign icon-white"></span> <I18nWrapper id="common.button.forgot_password" /></a></p>
            }
          </form>
        </div>

        <div className="loginshadow animate3 fadeInUp"></div>

        {
          errorMessage &&
          <Alert type={AlertType.ERROR}>{errorMessage}</Alert>
        }

        {
          infoMessage &&
          <Alert type={AlertType.INFO}>{infoMessage}</Alert>
        }
      </div>
    </div>
  )
};
