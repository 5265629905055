import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { getBaseName, getRenderedMessage, getRenderedMessageFromTemplate } from '../../../utils';
import { Alert, AlertType } from '../../../Alert';
import { IphonePreview } from '../../IphonePreview';
import { IExpressFormValues, ITemplate, Nullable } from '../../../types';
import { I18nWrapper } from '../../I18nWrapper';
import { HtmlTooltip } from '../../HtmlTooltip';
import { TemplateVariableDate } from './TemplateVariable/TemplateVariableDate';
import { TemplateVariableTime } from './TemplateVariable/TemplateVariableTime';
import { TemplateVariableSelect } from './TemplateVariable/TemplateVariableSelect';
import { TemplateVariableText } from './TemplateVariable/TemplateVariableText';

import { useSnackbar } from 'notistack';
import { useIntl } from 'react-intl';
import SmsCounter from '@marcinkowalczyk/sms-counter';
import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Divider,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Checkbox,
  FormLabel,
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';
import { useStyles } from './classes';

import { getSendersSms, getSmsExpressConfig, getSmsExpressTemplates, sendSmsExpress } from '../../../api';
import { SenderType } from '../../../Sender';
import { parseISO, format } from 'date-fns';
import { SendExpressConfig } from '../../../SendExpressConfig';

const initialFormValues: IExpressFormValues = {
  messageType: 'template',
  selectedTemplateId: '',
  dynamicParameters: [],
  selectedSender: '',
  inputMessage: '',
  inputMobileNumbers: '',
  checkboxScheduleDelivery: '',
  inputScheduleDeliveryDate: format(new Date(), "yyyy-MM-dd'T'HH:mm"),
};

export const Express = () => {
  const classes = useStyles();
  const [errorMessage, setErrorMessage] = useState<Nullable<string>>(''); // DO we really need this? or will we use snackbar?
  const [infoMessage, setInfoMessage] = useState('');
  const [submitButtonLoading, setSubmitButtonLoading] = useState(false);
  const [scheduleDelivery, setScheduleDelivery] = useState(false);
  const [formValues, setFormValues] = useState(initialFormValues);
  const { enqueueSnackbar } = useSnackbar();
  const intl = useIntl();

  const setFormValue = (aKey: string) => (aValue: any) => {
    setFormValues((formValues) => {
      return { ...formValues, [aKey]: aValue };
    });
  };

  const [messageType, setMessageType] = [
    formValues.messageType,
    (aMessageType: string) => setFormValue('messageType')(aMessageType),
  ];
  const [selectedTemplateId, setSelectedTemplateId] = [
    formValues.selectedTemplateId,
    (aTemplateId: string) => setFormValue('selectedTemplateId')(aTemplateId),
  ];
  const [selectedSender, setSelectedSender] = [
    formValues.selectedSender,
    (aSender: string) => setFormValue('selectedSender')(aSender),
  ];
  const [inputMobileNumbers, setInputMobileNumbers] = [
    formValues.inputMobileNumbers,
    (mobileNumbers: string) => setFormValue('inputMobileNumbers')(mobileNumbers),
  ];
  const [inputScheduleDeliveryDate, setInputScheduleDeliveryDate] = [
    formValues.inputScheduleDeliveryDate,
    (date: string) => setFormValue('inputScheduleDeliveryDate')(date),
  ];

  const [senders, setSenders] = useState<SenderType[]>([]);
  const [config, setConfig] = useState<SendExpressConfig>({
    allowCustomMessages: false
  });

  const [templates, setTemplates] = useState<ITemplate[]>([]);
  const selectedTemplate = useMemo(
    () => templates.find((t) => t.id === selectedTemplateId) || null,
    [selectedTemplateId, templates]
  );
  const [templateVariables, setTemplateVariables] = useState({});

  useEffect(() => {
    resetTemplateVariables();
  }, [selectedTemplate, setTemplateVariables]);

  useEffect(() => {
    const doUseEffect = async () => {
      // Parallelize services calls
      const [templates, senders, config] = await Promise.all([getSmsExpressTemplates(), getSendersSms(), getSmsExpressConfig()]);

      setTemplates(templates);

      if (templates.length) {
        // Automatically choose the default template, or fallback to the first one
        let defaultTemplate = templates.find((elt) => elt.default === true);
        if (defaultTemplate) {
          setSelectedTemplateId(defaultTemplate.id);
        } else {
          // Automatically choose the first template
          const first = templates[0];
          setSelectedTemplateId(first.id);
        }
      }

      setSenders(senders);

      if (senders.length) {
        // Automatically choose the default sender
        let defaultSender = senders.find((elt) => elt.isDefault === true);
        if (defaultSender) {
          setSelectedSender(defaultSender.senderId);
        } else {
          setSelectedSender('');
        }

        setConfig(config);
      }
    };
    doUseEffect();
  }, []); // exhaustive-deps warning needs to be ignored here: setSelectedTemplateId is modified at each render, so if we add it in the dependencies the useEffect will be called infinitely

  const resultingSender =
    formValues.messageType === 'custom'
      ? formValues.selectedSender === '_none'
        ? ''
        : formValues.selectedSender
      : selectedTemplate?.sender || '';
  const resultingMessage =
    formValues.messageType === 'custom'
      ? formValues.inputMessage
      : getRenderedMessageFromTemplate(selectedTemplate, templateVariables, true);

  const getSmsCounterText = useCallback(
    (smsCounterInfo: any) => {
      let smsCounterText = smsCounterInfo.length + ' ';

      if (smsCounterInfo.length > 1) {
        smsCounterText += intl.formatMessage({ id: 'common.characters' });
      } else {
        smsCounterText += intl.formatMessage({ id: 'common.character' });
      }

      smsCounterText += ' = ' + smsCounterInfo.messages + ' ' + intl.formatMessage({ id: 'common.sms' });

      return smsCounterText;
    },
    [intl]
  );

  const { smsCounterInfo, smsCounterText } = useMemo(() => {
    const smsCounterInfo = SmsCounter.count(resultingMessage);
    const smsCounterText = getSmsCounterText(smsCounterInfo);
    return { smsCounterInfo, smsCounterText };
  }, [resultingMessage, getSmsCounterText]);

  function handleChangeMessage(event: React.ChangeEvent<HTMLTextAreaElement>) {
    const newFormValues = { ...formValues };

    newFormValues.inputMessage = event.target.value;

    setFormValues(newFormValues);
  }

  function handleChangeScheduleDelivery(event: React.ChangeEvent<HTMLInputElement>) {
    setFormValue('inputScheduleDeliveryDate')(format(new Date(), "yyyy-MM-dd'T'HH:mm"));

    setScheduleDelivery(event.target.checked);
  }

  /*
  function verifyStopMention() {
    const stopMention = 'STOP au 36180';
    const posMention = message.indexOf(stopMention);

    // If using a sender and sendings are marketing related, STOP mention is required
    const isMarketing = true; // should be passed in parameter?
    if (formValues.selectedSender !== '' && isMarketing) {
      // If it's missing, add it
      if (posMention === -1) {
        message += '\n' + stopMention;
        // textAreaMessageSms.caretTo(chaine.length);
      }
      // If it's present, remove everything after
      else {
        message = message.substring(0, posMention + stopMention.length);
      }
    } else {
      // Remove it on other cases
      if (posMention !== -1) {
        var regex = new RegExp('\n' + stopMention, 'g')
        message = message.replace(regex, '');
      }
    }

    const newFormvalues = { ...formValues };
    newFormvalues.inputMessage = message;
    setFormValues(newFormvalues);

    return message;
  }
  */

  async function handleSubmit(event: React.FormEvent<HTMLFormElement> | null) {
    // Prevent reclick on button if there's a pending request
    if (submitButtonLoading) {
      return;
    }

    event?.preventDefault();

    if (!resultingMessage) {
      enqueueSnackbar(intl.formatMessage({ id: 'send.alert.empty_message' }), { variant: 'error' });
      return;
    }

    if (formValues.messageType === 'template') {
      for (let aVar of Object.values(templateVariables)) {
        if (!aVar) {
          enqueueSnackbar(intl.formatMessage({ id: 'send.alert.empty_variables' }), { variant: 'error' });
          return;
        }
      }
    }

    setSubmitButtonLoading(true);

    try {
      const unicode = smsCounterInfo.encoding === 'UTF16';
      const phoneNumbers = inputMobileNumbers.split(/[\n;,]/).map((x) => x.trim());
      let sendingDate = parseISO(inputScheduleDeliveryDate).toISOString();

      if (!scheduleDelivery) {
        let currentDate = new Date();
        sendingDate = new Date(currentDate.getTime() - (3600000)).toISOString(); // minus 1 hour to be sure the message will be considered immediate and not delayed
      }

      const json = await sendSmsExpress(resultingSender, resultingMessage, phoneNumbers, sendingDate, unicode);

      setSubmitButtonLoading(false);

      if (json.success) {
        let successMsg = null;

        if (json.data.sendingType === 'delayed') {
          successMsg = intl.formatMessage(
            { id: 'send.notification.successful_sending.delayed.plural' },
            { count: json.data.nbContacts }
          );
        } else {
          successMsg = intl.formatMessage(
            { id: 'send.notification.successful_sending.immediate.plural' },
            { count: json.data.nbContacts }
          );
        }

        enqueueSnackbar(successMsg, { variant: 'success' });

        resetFormValues();

        return;
      }

      let errorMsg = null;

      switch (json.data.code) {
        case 'missingParameter':
          errorMsg = intl.formatMessage({ id: 'common.alert.empty_field' });
          break;

        case 'unauthorizedEmailInMessage':
          // TODO: implement authorization request modal
          errorMsg = intl.formatMessage({ id: 'send.alert.unauthorized_email_in_message' });
          break;

        case 'unauthorizedPhoneNumberInMessage':
          // TODO: implement authorization request modal
          errorMsg = intl.formatMessage({ id: 'send.alert.unauthorized_phone_number_in_message' });
          break;

        case 'unauthorizedKeywordInMessage':
          // TODO: implement authorization request modal
          errorMsg = intl.formatMessage({ id: 'send.alert.unauthorized_keyword_in_message' });
          break;

        case 'noValidContactFound':
          errorMsg = intl.formatMessage({ id: 'send.alert.no_valid_contact_found' });
          break;

        case 'incorrectSender':
          errorMsg = intl.formatMessage({ id: 'send.alert.incorrect_sender' }, { sender: resultingSender });
          break;

        case 'unicodeDetected':
          errorMsg = intl.formatMessage({ id: 'send.alert.unicode_detected' });
          break;

        case 'noCredits':
          errorMsg = intl.formatMessage({ id: 'send.alert.no_credits' });
          break;

        case 'stopMentionNotFound':
          errorMsg = intl.formatMessage({ id: 'send.alert.stop_mention_not_found' });
          break;

        case 'legalMentionsNotChecked':
          errorMsg = intl.formatMessage({ id: 'send.alert.legal_mentions_not_checked' });
          break;

        case 'unauthorizedCommercialSending':
          errorMsg = intl.formatMessage({ id: 'send.alert.unauthorized_commercial_sending' });
          break;

        case 'errorSendingDate':
          errorMsg = intl.formatMessage({ id: 'send.alert.error_sending_date' });
          break;

        case 'multipleTrackingLinks':
          errorMsg = intl.formatMessage({ id: 'send.alert.multiple_tracking_links' });
          break;

        default:
          errorMsg = intl.formatMessage(
            { id: 'common.alert.general_error' },
            { support_email: (window as any).SUPPORT_EMAIL }
          );
          break;
      }

      enqueueSnackbar(errorMsg, { variant: 'error' });

      // If redirect param specified in response, use it
      if (json.data.action === 'redirect' && json.data.redirectUrl) {
        window.location.href = json.data.redirectUrl;
      }
    } catch (e: any) {
      console.error('An error occured: ', e);
    }
  }

  function resetTemplateVariables() {
    if (!selectedTemplate) {
      return;
    }

    setTemplateVariables(Object.fromEntries(selectedTemplate.variables.map((x) => [x.name, x.initialValue || ''])));
  }

  function resetFormValues() {
    // Empty the mobile number field and reset the date
    setFormValue('inputMobileNumbers')('');
    setFormValue('inputScheduleDeliveryDate')(format(new Date(), "yyyy-MM-dd'T'HH:mm"));

    // Stay on the same type and empty fields
    if (formValues.messageType === 'template') {
      resetTemplateVariables();
    } else {
      setFormValue('inputMessage')('');
    }
  }

  return (
    <>
      <div id="contentForm">
        <form id="sendSmsForm" name="sendSmsForm" className="" method="post" onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={7}>
              <h4 className="widgettitle ctitle" style={{ margin: '0 0 0px 0 !important' }}>
                <I18nWrapper id="send.form.subtitle.select_type" />
              </h4>

              <Grid item>
                <RadioGroup
                  row
                  aria-label="type"
                  name="row-radio-buttons-group"
                  value={messageType}
                  onChange={(event, messageType) => setMessageType(messageType)}
                  className={classes.formControl}
                >
                  <FormControlLabel
                    value="template"
                    control={<Radio />}
                    label={intl.formatMessage({ id: 'send.form.select_type.template' })}
                  />
                  {config.allowCustomMessages && (<FormControlLabel
                    value="custom"
                    control={<Radio />}
                    label={intl.formatMessage({ id: 'send.form.select_type.custom' })}
                  />
                  )}
                </RadioGroup>
              </Grid>

              {messageType === 'template' && (
                <FormControl className={classes.formControl} key="template">
                  <InputLabel id="inputTemplate">{intl.formatMessage({ id: 'send.form.select_template' })}</InputLabel>
                  <Select
                    id="inputTemplateSelect"
                    labelId="inputTemplate"
                    value={selectedTemplateId}
                    onChange={(event) => setSelectedTemplateId(event.target.value as string)}
                  >
                    {templates.map((t) => (
                      <MenuItem key={t.id} value={t.id}>
                        {t.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}

              {messageType === 'custom' && (
                <>
                  <h4 className="widgettitle ctitle" style={{ margin: '0 0 0px 0 !important' }}>
                    {intl.formatMessage({ id: 'send.form.subtitle.custom' })}
                  </h4>
                  <Grid item>
                    <Box mt={1}>
                      <FormControl className={classes.formControl} key="custom">
                        <InputLabel id="inputCustom">{intl.formatMessage({ id: 'common.sender' })}</InputLabel>
                        <Select
                          id="inputCustomSelect"
                          labelId="inputCustom"
                          value={selectedSender}
                          onChange={(event) => setSelectedSender(event.target.value as string)}
                        >
                          <MenuItem key={'_none'} value={'_none'}>
                            {intl.formatMessage({ id: 'common.none' })}
                          </MenuItem>
                          {senders.map((t) => (
                            <MenuItem key={t.id} value={t.senderId}>
                              {t.senderId}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  </Grid>

                  <Grid item>
                    <Box mt={1}>
                      <TextField
                        id="message"
                        name="message"
                        label={intl.formatMessage({ id: 'common.message' })}
                        multiline
                        minRows={4}
                        variant="outlined"
                        value={formValues.inputMessage}
                        onChange={handleChangeMessage}
                        placeholder={intl.formatMessage({ id: 'send.form.input.message' })}
                        autoFocus
                        fullWidth
                        className={classes.formControl}
                      />
                    </Box>
                  </Grid>
                </>
              )}

              {messageType === 'template' && selectedTemplate && (
                <div>
                  <h4 className="widgettitle ctitle" style={{ margin: '0 0 0px 0 !important' }}>
                    {intl.formatMessage({ id: 'send.form.subtitle.template' })}
                  </h4>
                  {selectedTemplate.variables.map((v) => {
                    if (v.type === 'select') {
                      return (
                        <Box mt={1} key={v.name}>
                          <TemplateVariableSelect
                            variableDefinition={v}
                            variableValue={(templateVariables as any)[v.name] || ''}
                            onChange={(newValue: any) =>
                              setTemplateVariables({
                                ...templateVariables,
                                ...{ [v.name]: newValue },
                              })
                            }
                          />
                        </Box>
                      );
                    }
                    if (v.type === 'text') {
                      return (
                        <Box mt={1} key={v.name}>
                          <TemplateVariableText
                            variableDefinition={v}
                            variableValue={(templateVariables as any)[v.name] || ""}
                            onChange={(newValue: any) =>
                              setTemplateVariables({
                                ...templateVariables,
                                ...{ [v.name]: newValue },
                              })
                            }
                          />
                        </Box>
                      );
                    }
                    if (v.type === 'date') {
                      return (
                        <Box mt={1} key={v.name}>
                          <TemplateVariableDate
                            variableDefinition={v}
                            variableValue={(templateVariables as any)[v.name] || ''}
                            onChange={(newValue: any) =>
                              setTemplateVariables({
                                ...templateVariables,
                                ...{ [v.name]: newValue },
                              })
                            }
                          />
                        </Box>
                      );
                    }
                    if (v.type === 'time') {
                      return (
                        <Box mt={1} key={v.name}>
                          <TemplateVariableTime
                            variableDefinition={v}
                            variableValue={(templateVariables as any)[v.name] || ''}
                            onChange={(newValue: any) =>
                              setTemplateVariables({
                                ...templateVariables,
                                ...{ [v.name]: newValue },
                              })
                            }
                          />
                        </Box>
                      );
                    }

                    return (
                      <Box mt={1} key={v.name}>
                        <div>Variable type not handled</div>
                      </Box>
                    );
                  })}
                </div>
              )}

              <Box mt={1}>
                <h4 className="widgettitle ctitle" style={{ margin: '0 0 0px 0 !important' }}>
                  <I18nWrapper id="send.form.subtitle.configure_sending" />
                </h4>

                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      id="mobileNumbers"
                      name="Mobile numbers"
                      label={intl.formatMessage({ id: 'common.mobile_numbers' })}
                      value={inputMobileNumbers}
                      onChange={(event) => setInputMobileNumbers(event.target.value)}
                      required
                      multiline
                      minRows={1}
                      className={classes.formControl}
                    />
                    <HtmlTooltip
                      title={intl.formatMessage({ id: 'send.tooltip.mobile_numbers.title' })}
                      content={intl.formatMessage({ id: 'send.tooltip.mobile_numbers.description' })}
                    >
                      <IconButton aria-label="tooltip-mobile-numbers">
                        <InfoIcon fontSize="small" />
                      </IconButton>
                    </HtmlTooltip>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl component="fieldset">
                      <FormLabel component="legend">
                        <FormControlLabel
                          label={intl.formatMessage({ id: 'common.schedule_delivery' })}
                          control={
                            <Checkbox
                              color="primary"
                              value={scheduleDelivery}
                              onChange={handleChangeScheduleDelivery}
                            />
                          }
                          labelPlacement="start"
                        ></FormControlLabel>
                      </FormLabel>
                      {scheduleDelivery && (
                        <TextField
                          id="datetime-local"
                          type="datetime-local"
                          label={intl.formatMessage({ id: 'common.date_and_hour' })}
                          className={classes.formControl}
                          value={inputScheduleDeliveryDate}
                          onChange={(event) => setInputScheduleDeliveryDate(event.target.value)}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      )}
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <Button type="submit" variant="contained" color="primary" fullWidth>
                      <img
                        src={getBaseName() + '/img/spinner.gif'}
                        style={{
                          width: '15px',
                          float: 'left',
                          marginRight: '10px',
                          display: submitButtonLoading ? 'block' : 'none',
                        }}
                        alt="loading"
                      />
                      {intl.formatMessage({ id: 'send.form.button.send' })}
                    </Button>
                  </Grid>
                </Grid>
              </Box>

              {errorMessage && <Alert type={AlertType.ERROR}>{errorMessage}</Alert>}

              {infoMessage && <Alert type={AlertType.INFO}>{infoMessage}</Alert>}
            </Grid>

            <Grid item xs={12} sm={5}>
              <Grid item>
                <IphonePreview sender={resultingSender} message={resultingMessage} />
              </Grid>

              <Grid container style={{ textAlign: 'center' }}>
                <Grid item xs={12}>
                  <span className="smsCounter">{smsCounterText}</span>

                  <HtmlTooltip
                    title={<I18nWrapper id="send.tooltip.message.title" />}
                    content={
                      <>
                        <Box>
                          <b>
                            <I18nWrapper id="send.tooltip.message.message_length.subtitle" />
                          </b>
                        </Box>
                        <Box>
                          <I18nWrapper id="send.tooltip.message.message_length.description" />
                        </Box>
                      </>
                    }
                  >
                    <IconButton aria-label="tooltip-message">
                      <InfoIcon fontSize="small" />
                    </IconButton>
                  </HtmlTooltip>
                </Grid>

                <Grid item xs={12}>
                  {smsCounterInfo.encoding === 'UTF16' && (
                    <div>
                      <I18nWrapper id="send.warning.unicode_detected" />
                      <HtmlTooltip
                        title={<I18nWrapper id="send.tooltip.unicode.title" />}
                        content={
                          <>
                            <Box>
                              <I18nWrapper id="send.tooltip.unicode.description" />
                            </Box>
                            <Box mt={1}>
                              <WarningIcon fontSize="small" />
                              &nbsp;
                              <I18nWrapper id="send.tooltip.unicode.description_bouygues" />
                            </Box>
                          </>
                        }
                      >
                        <IconButton aria-label="tooltip-unicode">
                          <InfoIcon fontSize="small" />
                        </IconButton>
                      </HtmlTooltip>
                    </div>
                  )}
                </Grid>

                <Divider />
              </Grid>
            </Grid>
          </Grid>
        </form>
      </div>
    </>
  );
};
