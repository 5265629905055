import React from 'react';
import { I18nWrapper } from '../../I18nWrapper';
import { useIntl } from 'react-intl';

type TwoFaConfiguringProps = {
  handleEnable: (event: React.FormEvent<HTMLFormElement>) => Promise<void>,
  urlQrCode: string,
  secretCode: string,
  backupCode: string,
  code: string,
  handleCode: (event: React.ChangeEvent<HTMLInputElement>) => void,
}

export const TwoFaConfiguring = ({ handleEnable, urlQrCode, secretCode, backupCode, code, handleCode }: TwoFaConfiguringProps) => {
  const intl = useIntl();

  return (
    <div id="configure2fa" >
      <h4 className="widgettitle ctitle"><I18nWrapper id="twofa.configuring.title" /></h4>
      <ul className="twofaul">
        <li>
          <div id="step1">
            <div className="twofatitle "><I18nWrapper id="twofa.configuring.step_1.title" /></div>
            <p><I18nWrapper id="twofa.configuring.step_1.description_1" /></p>
            <img src={urlQrCode} className="qrcode" style={{ width: '200px', height: '200px' }} alt="2FA QR Code"></img>
            <p><I18nWrapper id="twofa.configuring.step_1.description_2" /></p>
            <input
              type="text"
              name="manualEntry"
              className="form-control manualEntry"
              id="manualEntry"
              value={secretCode}
              readOnly
            />
          </div>
        </li>
        <li>
          <div id="step2">
            <div className="twofatitle "><I18nWrapper id="twofa.configuring.step_2.title" /></div>
            <p><I18nWrapper id="twofa.configuring.step_2.description_1" /></p>
            <p><strong><I18nWrapper id="common.note" /></strong>&nbsp;<I18nWrapper id="twofa.configuring.step_2.description_2" /></p>
            <h2><I18nWrapper id="twofa.backup_code" /></h2>
            <input
              type="text"
              name="backupCode"
              className="form-control backupCode"
              id="backupCode"
              value={backupCode}
              readOnly
            />
          </div>
        </li>
        <li>
          <div id="step3">
            <div className="twofatitle "><I18nWrapper id="twofa.configuring.step_3.title" /></div>
            <p><I18nWrapper id="twofa.configuring.step_3.description_1" /></p>
            <p><strong><I18nWrapper id="common.note" /></strong>&nbsp;<I18nWrapper id="twofa.configuring.step_3.description_2" /></p>
            <h2><I18nWrapper id="twofa.configuring.step_3.enter_code" /></h2>
            <form id="frmstep3" onSubmit={handleEnable}>
              <input
                type="number"
                name="code"
                className="form-control code"
                placeholder={intl.formatMessage({ id: "common.otp_code" })}
                id="code"
                value={code}
                required
                pattern="\d{6}"
                onChange={handleCode}
              />
              <button type="submit" className="btn btn-default btn-block btnVerify"><I18nWrapper id="twofa.configuring.button.verify" /></button>
            </form>
          </div>
        </li>
      </ul>
    </div>
  )
}
