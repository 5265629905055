import React, { useEffect, useState, useContext, SyntheticEvent } from 'react';

import { UserContext } from '../Context';
import { OrganizationGeneralSettings } from "./components/OrganizationGeneralSettings";
import { OrganizationReportSettings } from "./components/OrganizationReportSettings";
import { enableOrganizationTwoFaEnforcement, disableOrganizationTwoFaEnforcement, switchOrganizationReportPeriodicity } from '../../api';

import { useSnackbar } from 'notistack';
import { useIntl } from 'react-intl';

export enum OrganizationState {
  SETTINGS = 'settings',
  REPORTS = 'reports'
};

type OrganizationProps = {
  currentState : OrganizationState
}

export const Organization = ({currentState}:OrganizationProps) => {
  
  const [currentOrganizationState, setCurrentOrganizationState] = useState(currentState);
  const [organizationId, setOrganizationId] = useState<number>(0);
  const [twoFaEnforcedSwitchState, setTwoFaEnforcedSwitchState] = useState(false);
  const [dailySwitchState, setDailySwitchState] = useState(false);
  const [weeklySwitchState, setWeeklySwitchState] = useState(false);
  const [monthlySwitchState, setMonthlySwitchState] = useState(false);
  const [readyForDisplay, setReadyForDisplay] = useState(false);

  const userContext = useContext(UserContext);

  const { enqueueSnackbar } = useSnackbar();
  const intl = useIntl();

  useEffect(() => {
    if (!userContext.isLoggedIn || !userContext.user || !userContext.user.organization) {
      return;
    }
    
    setOrganizationId(userContext.user.organization.id);
    setTwoFaEnforcedSwitchState(userContext.user.organization.twoFaEnforced);
    if(userContext.user.organization.volumesReporting) {
      setDailySwitchState(userContext.user.organization.volumesReporting.daily);
      setWeeklySwitchState(userContext.user.organization.volumesReporting.weekly);
      setMonthlySwitchState(userContext.user.organization.volumesReporting.monthly);
    }

    setReadyForDisplay(true);
  }, [userContext]);

  if (!userContext.isLoggedIn || !userContext.user || !userContext.user.organization || !readyForDisplay) {
    return null;
  }

  async function handleSwitchTwoFaEnforcement(checked: boolean, event: MouseEvent | SyntheticEvent<MouseEvent | KeyboardEvent, Event>) {
    //console.log('checked: ', checked);
    //console.log('event: ', event);

    setTwoFaEnforcedSwitchState(checked);

    // According to the switch state, call the right method
    if (checked) {
      enableTwoFaEnforcement();
    } else {
      disableTwoFaEnforcement();
    }
  }

  async function handleSwitchReportsDaily(checked: boolean, event: MouseEvent | SyntheticEvent<MouseEvent | KeyboardEvent, Event>) {    
    updateReportingPeriodicity('daily', checked);
    //setDailySwitchState(checked);
  }
  async function handleSwitchReportsWeekly(checked: boolean, event: MouseEvent | SyntheticEvent<MouseEvent | KeyboardEvent, Event>) {    
    updateReportingPeriodicity('weekly', checked);
    //setWeeklySwitchState(checked);
  }
  async function handleSwitchReportsMonthly(checked: boolean, event: MouseEvent | SyntheticEvent<MouseEvent | KeyboardEvent, Event>) {    
    updateReportingPeriodicity('monthly', checked);
    //setMonthlySwitchState(checked);
  }

  async function enableTwoFaEnforcement() {
    try {
      const json = await enableOrganizationTwoFaEnforcement(organizationId);

      if (json.success) {
        enqueueSnackbar(intl.formatMessage({ id: "organization.notification.enable_org_twofa_enforcement" }), { variant: 'success' });

        return;
      }

      let errorMsg = null;

      switch (json.data.code) {
        case 'twoFaAlreadyEnforced':
          return;

        case 'orgNotFound':
          errorMsg = intl.formatMessage({ id: "organization.alert.org_not_found" })?.toString();
          break;

        // Move the 2 cases below in a general handler?
        case 'missingParameter':
          errorMsg = intl.formatMessage({ id: "common.alert.empty_field" })?.toString();
          break;

        default:
          errorMsg = intl.formatMessage({ id: "common.alert.general_error" }, { support_email: (window as any).SUPPORT_EMAIL })?.toString();
          break;
      }

      // Restore switch to the off position
      setTwoFaEnforcedSwitchState(false);

      enqueueSnackbar(errorMsg, { variant: 'error' });
    } catch (e: any) {
      console.error('An issue happened while enabling')
    }
  }

  async function disableTwoFaEnforcement() {
    try {
      const json = await disableOrganizationTwoFaEnforcement(organizationId);

      if (json.success) {
        enqueueSnackbar(intl.formatMessage({ id: "organization.notification.disable_org_twofa_enforcement" }), { variant: 'success' });

        return;
      }

      let errorMsg = null;

      switch (json.data.code) {
        case 'twoFaWasNotEnforced':
          return;

        case 'orgNotFound':
          errorMsg = intl.formatMessage({ id: "organization.alert.org_not_found" })?.toString();
          break;

        case 'missingParameter':
          errorMsg = intl.formatMessage({ id: "common.alert.empty_field" })?.toString();
          break;

        default:
          errorMsg = intl.formatMessage({ id: "common.alert.general_error" }, { support_email: (window as any).SUPPORT_EMAIL })?.toString();
          break;
      }

      // Restore switch to the on position
      setTwoFaEnforcedSwitchState(true);

      enqueueSnackbar(errorMsg, { variant: 'error' });
    } catch (e: any) {
      console.error('An issue happened while disabling')
    }
  }

  async function updateReportingPeriodicity(period:string, checked:boolean) {
      
      try {
        const json = await switchOrganizationReportPeriodicity(organizationId, period, checked);
        
        if (json.success) {
          switch (period) {
            case 'daily':
              setDailySwitchState(checked);
              break;
            case 'weekly':
              setWeeklySwitchState(checked);
              break;
            case 'monthly':
              setMonthlySwitchState(checked);
              break;
          }
          enqueueSnackbar(intl.formatMessage({ id: "organization.notification.switch_reporting_periodicity" }), { variant: 'success' });  
          return;
        } else {
          switch (period) {
            case 'daily':
              setDailySwitchState(!checked);
              break;
            case 'weekly':
              setWeeklySwitchState(!checked);
              break;
            case 'monthly':
              setMonthlySwitchState(!checked);
              break;
          }
          enqueueSnackbar(intl.formatMessage({ id: "organization.notification.switch_reporting_periodicity_error" }), { variant: 'error' });
        }
  
        let errorMsg = null;
  
  
        // Restore switch to the off position
        //setTwoFaEnforcedSwitchState(false);
  
        //enqueueSnackbar(errorMsg, { variant: 'error' });
      } catch (e: any) {
        console.error('An issue happened while enabling')
      }
  }
  
  // Pass values as functions to avoid unncessary computation (if they were passed "normally", code would be executed)

  const mapping: { [x in OrganizationState]: () => JSX.Element } = {
    [OrganizationState.SETTINGS]: () => <OrganizationGeneralSettings handleSwitchTwoFaEnforcement={handleSwitchTwoFaEnforcement} currentTwoFaEnforcementState={twoFaEnforcedSwitchState} />,
    [OrganizationState.REPORTS]: () => <OrganizationReportSettings handleSwitchReportsDaily={handleSwitchReportsDaily} handleSwitchReportsWeekly={handleSwitchReportsWeekly} handleSwitchReportsMonthly={handleSwitchReportsMonthly} currentDailyState={dailySwitchState} currentWeeklyState={weeklySwitchState} currentMonthlytState={monthlySwitchState} />,
  }

  return mapping[currentOrganizationState]();
};
