import React, { SyntheticEvent } from 'react';
import { I18nWrapper } from '../../I18nWrapper';
// import { useIntl } from 'react-intl';
import Switch from "react-switch";

type OrganizationReportSettingsProps = {
  handleSwitchReportsDaily: (checked: boolean, event: MouseEvent | SyntheticEvent<MouseEvent | KeyboardEvent, Event>) => void,
  handleSwitchReportsWeekly: (checked: boolean, event: MouseEvent | SyntheticEvent<MouseEvent | KeyboardEvent, Event>) => void,
  handleSwitchReportsMonthly: (checked: boolean, event: MouseEvent | SyntheticEvent<MouseEvent | KeyboardEvent, Event>) => void,
  currentDailyState: boolean,
  currentWeeklyState: boolean,
  currentMonthlytState: boolean,
}

export const OrganizationReportSettings = ({ handleSwitchReportsDaily, handleSwitchReportsWeekly, handleSwitchReportsMonthly, currentDailyState, currentWeeklyState, currentMonthlytState }: OrganizationReportSettingsProps) => {
  
  return (
    <div id="securitysettings">
      <h4 className="widgettitle ctitle"><I18nWrapper id="organization.reports_settings.title" /></h4>
      <div id="reportsVolume">        
        <div className="optionContent">
          <p className="optionDesc"><I18nWrapper id="organization.reports_settings.description" /></p>
          <p className="optionDesc"><I18nWrapper id="organization.reports_settings.checkboxes" /></p>      
          <div className="checkboxesContent"><I18nWrapper id="organization.reports_settings.daily" /></div><Switch onChange={handleSwitchReportsDaily} checked={currentDailyState} />
          <div className="checkboxesContent"><I18nWrapper id="organization.reports_settings.weekly" /></div><Switch onChange={handleSwitchReportsWeekly} checked={currentWeeklyState} />
          <div className="checkboxesContent"><I18nWrapper id="organization.reports_settings.monthly" /></div><Switch onChange={handleSwitchReportsMonthly} checked={currentMonthlytState} />
        </div>
      </div>
    </div>
  )
}
