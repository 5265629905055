import React, { useState } from 'react';

import format from 'date-fns/format';
import { useSnackbar } from 'notistack';
import { useIntl } from 'react-intl';

import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TextField,
  Typography,
} from '@material-ui/core';
import {
  createStyles,
  makeStyles,
  Theme,
  withStyles,
} from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import { getSmsInfoByMobileNumber } from '../../../api';
import { ISmsInfo } from '../../../types';
import {
  getBaseName,
  getVariableFromWindow,
} from '../../../utils';
import { I18nWrapper } from '../../I18nWrapper';

const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: '#858585',
      color: theme.palette.common.white,
    },
  })
)(TableCell);

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

const useStyles = makeStyles((theme: Theme) => ({
  table: {
    minWidth: 700,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 220,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 220,
  },
}));

enum SearchByNumberState {
  UNLOADED = 'unloaded',
  LOADING = 'settings',
  LOADED = 'loaded',
}

export const SearchByNumber = () => {
  const [currentSearchByNumberState, setCurrentSearchByNumberState] = useState(SearchByNumberState.UNLOADED);
  const [smsInfo, setSmsInfo] = useState<Array<ISmsInfo>>([]);
  const [mobileNumber, setMobileNumber] = useState<string>('');

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const { enqueueSnackbar } = useSnackbar();
  const intl = useIntl();

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  async function handleLoadData(event: React.MouseEvent<HTMLButtonElement> | null) {
    if (currentSearchByNumberState === SearchByNumberState.LOADING) {
      return;
    }

    setCurrentSearchByNumberState(SearchByNumberState.LOADING);

    const date = new Date();
    const startDate = format(new Date(date.getFullYear(), date.getMonth() - 1, 1), 'yyyy-MM-dd').toString();
    const endDate = format(new Date(), 'yyyy-MM-dd');

    if (!startDate || !endDate) {
      enqueueSnackbar(
        intl.formatMessage(
          { id: 'common.alert.general_error' },
          { support_email: getVariableFromWindow<string>('SUPPORT_EMAIL') }
        ),
        { variant: 'error' }
      );

      return;
    }

    // Retrieve search results
    const [jsonSmsInfo] = await Promise.all([getSmsInfoByMobileNumber(mobileNumber, startDate, endDate)]);

    if (!jsonSmsInfo || !jsonSmsInfo.length) {
      enqueueSnackbar(intl.formatMessage({ id: 'common.alert.no_data_found' }), { variant: 'error' });

      setCurrentSearchByNumberState(SearchByNumberState.UNLOADED);

      return;
    }

    setSmsInfo(jsonSmsInfo);

    setCurrentSearchByNumberState(SearchByNumberState.LOADED);
  }

  const classes = useStyles();

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            id="mobileNumber"
            name="Mobile number"
            label={intl.formatMessage({ id: 'common.mobile_number' })}
            value={mobileNumber}
            onChange={(event: any) => setMobileNumber(event.target.value)}
            onKeyDown={(event: any) => event.keyCode === 13 ? handleLoadData(null) : null}
            required
            minRows={1}
            className={classes.formControl}
          />
        </Grid>
      </Grid>

      <button className="btn btn-default btn-block" style={{ display: 'inline-flex' }} onClick={handleLoadData}>
        <span style={{ margin: 'auto' }}>
          <img
            src={getBaseName() + '/img/spinner.gif'}
            style={{
              width: '15px',
              float: 'left',
              marginRight: '10px',
              display: currentSearchByNumberState === SearchByNumberState.LOADING ? 'block' : 'none',
            }}
            alt="loading"
          />
          <I18nWrapper id="campaign.searchbynumber.button.launch_search" />
        </span>
      </button>

      {currentSearchByNumberState === SearchByNumberState.LOADED && (
        <div style={{marginTop: '10px'}}>
          <Typography variant="h6" id="tableTitle" component="div">
            <I18nWrapper id="campaign.searchbynumber.title.search_results" />
          </Typography>
          <TableContainer component={Paper}>
            <Table className={classes.table} size="small" aria-label="Search results">
              <TableHead>
                <TableRow>
                  <StyledTableCell style={{ whiteSpace: 'nowrap' }} component="th" scope="row">
                    <I18nWrapper id="common.message" />
                  </StyledTableCell>

                  <StyledTableCell style={{ whiteSpace: 'nowrap' }} component="th" scope="row">
                    <I18nWrapper id="common.sender" />
                  </StyledTableCell>

                  <StyledTableCell style={{ whiteSpace: 'nowrap' }} component="th" scope="row">
                    <I18nWrapper id="campaign.searchbynumber.title.date_sent" />
                  </StyledTableCell>

                  <StyledTableCell style={{ whiteSpace: 'nowrap' }} component="th" scope="row">
                    <I18nWrapper id="campaign.searchbynumber.title.date_dlr" />
                  </StyledTableCell>

                  <StyledTableCell style={{ whiteSpace: 'nowrap' }} component="th" scope="row">
                    <I18nWrapper id="campaign.searchbynumber.title.dlr_status" />
                  </StyledTableCell>

                  <StyledTableCell style={{ whiteSpace: 'nowrap' }} component="th" scope="row">
                    <I18nWrapper id="campaign.searchbynumber.title.campaign_id" />
                  </StyledTableCell>

                  <StyledTableCell style={{ whiteSpace: 'nowrap' }}>
                    <I18nWrapper id="campaign.searchbynumber.title.sms_id" />
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {smsInfo.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((aSmsInfo) => (
                  <StyledTableRow key={aSmsInfo.smsId}>
                    <StyledTableCell style={{ minWidth: '430px' }}>{aSmsInfo.message}</StyledTableCell>
                    <StyledTableCell style={{ whiteSpace: 'nowrap' }}>{aSmsInfo.sender}</StyledTableCell>
                    <StyledTableCell style={{ whiteSpace: 'nowrap' }}>{aSmsInfo.dateSent}</StyledTableCell>
                    <StyledTableCell style={{ whiteSpace: 'nowrap' }}>{aSmsInfo.dateDlr}</StyledTableCell>
                    <StyledTableCell style={{ whiteSpace: 'nowrap' }}>{aSmsInfo.dlrStatusText}</StyledTableCell>
                    <StyledTableCell style={{ whiteSpace: 'nowrap' }}>{aSmsInfo.campaignId}</StyledTableCell>
                    <StyledTableCell style={{ whiteSpace: 'nowrap' }}>{aSmsInfo.smsId}</StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={smsInfo.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      )}
    </>
  );
};
