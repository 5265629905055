import {
  TextField,
} from '@material-ui/core';
import { TemplateVariableProps } from '../../../../types';
import { useStyles } from '../classes';

export const TemplateVariableText = ({ variableDefinition, variableValue, onChange }: TemplateVariableProps) => {
  const classes = useStyles();

  return (
    <TextField
      id={variableDefinition.name}
      name={variableDefinition.name}
      label={variableDefinition.label || variableDefinition.name}
      value={variableValue}
      onChange={(event) => {
        onChange(event.target.value as string)
      }}
      className={classes.formControl}
      required
    />
  );
};
