import './App.css';

import { SnackbarProvider } from 'notistack';
import { IntlProvider } from 'react-intl';
import {
  BrowserRouter as Router,
  Link,
  Route,
  Switch,
} from 'react-router-dom';

import Grow from '@material-ui/core/Grow';
import {
  enUS,
  frFR,
} from '@material-ui/core/locale';
import {
  createTheme,
  ThemeProvider,
} from '@material-ui/core/styles';

import { Login } from './components/Auth/components/Login';
import { ResetPass } from './components/Auth/components/ResetPass';
import {
  SearchByNumber,
} from './components/Campaign/components/SearchByNumber';
import {
  StatisticsByDay,
} from './components/Campaign/components/StatisticsByDay';
import { UserProvider } from './components/Context';
import {
  Organization,
  OrganizationState,
} from './components/Organization';
import { Express } from './components/Send/components/Express';
import { TwoFa } from './components/TwoFa';
import enMessages from './translations/en.json';
import frMessages from './translations/fr.json';
import { getBaseName } from './utils';

let baseName = getBaseName();

const messages = {
  fr: frMessages,
  en: enMessages
}

type supportedLanguages = keyof typeof messages;

// Variable USER_LANGUAGE must be initialized in JS
const currentLanguage = ((window as any).USER_LANGUAGE) as supportedLanguages;  // language without region code

let packageLocalization = enUS;
if (currentLanguage === 'fr') {
  packageLocalization = frFR;
}

const theme = createTheme({
  palette: {
    primary: {
      main: "#006dcc",
    },
    secondary: {
      main: "#666",
    },
  },
}, packageLocalization);

function AppWrapper() {
  return (
    <IntlProvider messages={messages[currentLanguage] || messages["en"]} locale="fr">
      <Router basename={baseName}>
        <UserProvider>
          <ThemeProvider theme={theme}>
            <SnackbarProvider maxSnack={3}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              TransitionComponent={Grow as any}
            >
              <App />
            </SnackbarProvider>
          </ThemeProvider>
        </UserProvider>
      </Router>
    </IntlProvider>
  );
}

const LinksDisplayer = () => {
  return (
    <div>
      <li>
        <Link to="/auth/login">/auth/login</Link>
      </li>
      <li>
        <Link to="/profil/twofa">/profil/twofa</Link>
      </li>
      <li>
        <Link to="/sender">/sender</Link>
      </li>
      <li>
        <Link to="/profil">/profil</Link>
      </li>
      <li>
        <Link to="/organization">/organization</Link>
      </li>
      <li>
        <Link to="/send/express">/send/express</Link>
      </li>
      <li>
        <Link to="/campaign/statisticsbyday">/campaign/statisticsbyday</Link>
      </li>
      <li>
        <Link to="/campaign/searchbynumber">/campaign/searchbynumber</Link>
      </li>
    </div>
  );
};

function App() {
  return (
    <Switch>
      <Route path="/auth/login">
        <Login />
      </Route>
      <Route path="/auth/resetpass/:token">
        <ResetPass />
      </Route>
      <Route path="/profil/twofa">
        <TwoFa />
      </Route>
      <Route path="/profil/">
        <TwoFa />
      </Route>
      <Route path="/organization/reports">
        <Organization currentState={OrganizationState.REPORTS} />
      </Route>
      <Route path="/organization/">
        <Organization currentState={OrganizationState.SETTINGS}/>
      </Route>
      <Route path="/organization/settings">
        <Organization currentState={OrganizationState.SETTINGS} />
      </Route>
      <Route path="/send/express">
        <Express />
      </Route>
      <Route path="/campaign/statisticsbyday*">
        <StatisticsByDay />
      </Route>
      <Route path="/campaign/statisticsbyday*">
        <StatisticsByDay />
      </Route>
      <Route path="/campaign/searchbynumber*">
        <SearchByNumber />
      </Route>
      <Route exact path="/">
        <LinksDisplayer />
      </Route>
    </Switch>
  );
}


export default AppWrapper;