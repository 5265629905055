import React from 'react';
import { Nullable } from '../types';
import {
  Typography,
  Tooltip,
  withStyles,
  Box,
} from '@material-ui/core';

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 300,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

// Material UI tooltip v4 & v5 (mui): https://stackoverflow.com/a/54606987
export const HtmlTooltip = ({ title, content, children }: { title?: Nullable<string | React.ReactNode>, content: any, children: React.ReactElement<any, any> }) => {
  return (
    <LightTooltip
      title={
        <>
          <Typography color="inherit">{title}</Typography>
          <Box>
            {content}
          </Box>
        </>
      }
    >
      {children}
    </LightTooltip>
  );
};