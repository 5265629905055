import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import { TemplateVariableProps } from '../../../../types';
import { useStyles } from '../classes';

export const TemplateVariableSelect = ({ variableDefinition, variableValue, onChange }: TemplateVariableProps) => {
  const labelId = variableDefinition.name + 'Label';
  const classes = useStyles();

  const values = variableDefinition.values;

  return (
    <FormControl className={classes.formControl}>
      <InputLabel id={labelId}>{variableDefinition.label || variableDefinition.name}</InputLabel>
      <Select
        id={variableDefinition.name}
        name={variableDefinition.name}
        labelId={labelId}
        value={variableValue}
        onChange={(event) => {
          onChange(event.target.value as string);
        }}
        autoWidth={true}
        required
      >
        {values &&
          values.map((aValue) => (
            <MenuItem key={aValue.key} value={aValue.key}>
              {aValue.label}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};
