import React from 'react';
import { I18nWrapper } from '../../I18nWrapper';

type TwoFaDisabledProps = {
  handleConfigure: (event: React.FormEvent<HTMLFormElement>) => Promise<void>
}

export const TwoFaDisabled = ({ handleConfigure }: TwoFaDisabledProps) => {
  return (
    <div id="twofa">
      <h4 className="widgettitle ctitle"><I18nWrapper id="twofa.totp.title" /></h4>
      <div id="active2fa">
        <div className="twofatitle "><I18nWrapper id="twofa.status" /><span id="twofastatus" className="disabled">&nbsp;<I18nWrapper id="twofa.status.disabled" /></span></div>
        <div className="twofadesc "><I18nWrapper id="twofa.disabled.description" /></div>
        <form name="frmenable2fa" onSubmit={handleConfigure}>
          <button type="submit" className="btn btn-default btn-block btConfigure"><I18nWrapper id="twofa.disabled.button.configure" /></button>
        </form>
      </div>
    </div>
  )
}
