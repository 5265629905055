import { format } from 'date-fns';
import nunjucks from 'nunjucks';
import { ITemplate } from './types';

export const getBaseName = () => {
  let baseName = window.__PUBLIC_URL__ || '';
  baseName = baseName.replace('/react-spa', '');
  return baseName;
};

export const getApiPrefix = () => {
  if (process.env.NODE_ENV === 'development') {
    return process.env.REACT_APP_API_PREFIX;
  }
  return getBaseName();
};

var env = nunjucks.configure({ autoescape: false });

env.addFilter('formatDate', (str, dateFormat) => formatDate(str, dateFormat));
env.addFilter('formatTime', (str, dateFormat) => str ?? formatDate('2021-01-01 ' + str, dateFormat));

const formatDate = (str: string, dateFormat: string) => {
  if (!str) {
    return str;
  }

  const date = new Date(str as string);
  if (isNaN(date.getTime())) {
    return str;
  }

  return format(date, dateFormat);
}

export const getRenderedMessage = (message: string, context: object) => {
  return nunjucks.renderString(message, context);
};

export const getRenderedMessageFromTemplate = (
  aTemplate: ITemplate | null,
  context: any,
  replaceVariableByKeyOrLabelIfEmpty: boolean = false
) => {
  if (!aTemplate) {
    return '';
  }

  if (!replaceVariableByKeyOrLabelIfEmpty) {
    return getRenderedMessage(aTemplate.content, context);
  }

  const newEntries = Object.entries(context).map(([key, value]) => {
    const variable = aTemplate.variables.find((elt) => elt.name === key);

    if (value !== '') {
      if (!variable || !variable.formatOutput) {
        return [key, value];
      }

      // Apply formatting if needed
      if (variable.type === 'date') {
        const newValue = formatDate(value as string, variable.formatOutput);
        return [key, newValue];
      } else if (variable.type === 'time') {
        // We need to add Y-m-d to the time in order to have a proper date
        const newValue = formatDate(('2021-01-01 ' + (value as string)), variable.formatOutput);
        return [key, newValue];
      }

      return [key, value];
    }

    let placeholder = `{{ ${key} }}`;

    if (variable && variable.label) {
      placeholder = `{{ ${variable.label} }}`;
    }

    return [key, placeholder];
  });
  const newContext = Object.fromEntries(newEntries);

  return getRenderedMessage(aTemplate.content, newContext);
};


export function getVariableFromWindow<T>(variableName: string): T | null {
  const w = window as any;
  if (w[variableName]) {
    return w[variableName];
  }

  return null;
};